import React from "react"

export const defaultInterfaceContext = {
  isCartOpen: false,
  isCheckout: false,
  isSideNavOpen: false,
  scrollPos: 0,
  prevScrollPos: 0,
  toggleCart: () => {},
  toggleSideNav: () => {},
}

export default React.createContext(defaultInterfaceContext)
