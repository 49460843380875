import React, { useState } from "react"
import styled from "@emotion/styled"
import { request } from "../../services/api"
import LocaleContext from "../../context/LocaleContext"

const NewsletterContainer = styled.div`
  display: flex;
  flex-direction: column;
  grid-area: newsletter;

  input {
    padding: 0.3rem 1rem;
    border: 1px solid white;
    background-color: white;
    height: 100%;
    margin-right: 1rem;
  }

  .input-row {
    height: 37px;
    display: flex;
    margin-top: 1rem;
    align-items: center;
  }
`

const SubmitButton = styled.div`
  cursor: pointer;

  display: flex;
  align-items: center;

  border: 1px solid white;

  padding-bottom: 1px;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-bottom: -1px;

  background-color: transparent;
  transition: background-color 200ms;
  height: 100%;

  &:hover {
    background-color: white;
    color: ${props => props.theme.colors.brand};
  }
`

const NewsletterInput = () => {
  const [email, setEmail] = useState("")
  const [done, setDone] = useState(false)
  const [error, setError] = useState(false)

  const handleChange = e => {
    if (error) {
      setError(false)
    }
    setEmail(e.target.value)
  }

  const handleSubmit = () => {
    request("POST", `/users/newsletter/${email}`)
      .then(() => {
        setDone(true)
      })
      .catch(() => {
        setError(true)
      })
  }

  if (done) {
    return (
      <LocaleContext.Consumer>
        {({ t }) => (
          <NewsletterContainer>
            <div>{t("newsletter_thanks")}</div>
          </NewsletterContainer>
        )}
      </LocaleContext.Consumer>
    )
  }

  return (
    <LocaleContext.Consumer>
      {({ t }) => (
        <NewsletterContainer>
          <div>{error ? t("newsletter_error") : t("newsletter_prompt")}</div>
          <div className="input-row">
            <input
              type="text"
              placeholder="EMAIL"
              value={email}
              onChange={handleChange}
            />
            <SubmitButton onClick={handleSubmit}>{t("Submit")}</SubmitButton>
          </div>
        </NewsletterContainer>
      )}
    </LocaleContext.Consumer>
  )
}

export default NewsletterInput
