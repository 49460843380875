import React from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import Img from "gatsby-image"
import { useInView } from "react-intersection-observer"

import Link from "./Link"
import mq from "../../../utils/mq"
import CTA from "./CTA"

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  ${props =>
    mq({
      padding: [
        `2rem ${props.theme.spacing.padding.content}`,
        `2rem ${props.theme.spacing.padding.content}`,
        `2rem calc(${props.theme.spacing.padding.content} * 2)`,
        `2rem calc(${props.theme.spacing.padding.content} * 3)`,
      ],
    })}
`

const Container = styled.div`
  display: grid;
  grid-column-gap: 1.5rem;
  grid-row-gap: 3rem;

  width: 100%;
  max-width: 1280px;
  margin: auto;

  ${props =>
    mq({
      gridTemplateColumns: [
        "1fr",
        "1fr",
        "repeat(auto-fit, minmax(300px, 1fr))",
      ],
    })}
`

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 800px;

  opacity: 0.2;
  transform: translate3d(0, 20px, 0);

  ${props =>
    props.animate &&
    `
    animation-name: fadeInUp;
    animation-duration: 2s;
    animation-fill-mode: both;
  `}
`

const Title = styled.h2`
  font-size: ${props => props.theme.typography.major};
  text-transform: uppercase;
`

const Body = styled.div`
  flex-grow: 1;
`

const MediaContainer = styled.div`
  margin-bottom: 2rem;
`

const MediaImg = styled(Img)`
  max-width: 100%;
  height: auto;
`

const CardBlock = ({ input }) => {
  const { cards } = input

  return (
    <Wrapper>
      <Container>
        {cards.map(card => {
          const [ref, inView, entry] = useInView({
            threshold: 0.2,
            triggerOnce: true,
          })

          const { title, cta, media, link } = card
          let { body } = card

          if (!body) {
            body = {
              childMarkdownRemark: {
                html: "",
              },
            }
          }

          return (
            <CardContainer animate={inView} ref={ref}>
              <MediaContainer>
                <MediaImg fluid={{ ...media.fluid, aspectRatio: 4 / 3 }} />
              </MediaContainer>
              <Title>{title}</Title>
              <Body
                dangerouslySetInnerHTML={{
                  __html: body.childMarkdownRemark.html,
                }}
              />
              <Link link={link}>
                <CTA>{cta}</CTA>
              </Link>
            </CardContainer>
          )
        })}
      </Container>
    </Wrapper>
  )
}

CardBlock.propTypes = {
  input: PropTypes.object.isRequired,
}

export default CardBlock
