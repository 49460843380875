import React from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import { StaticQuery, graphql } from "gatsby"
import { ThemeProvider } from "emotion-theming"

import LocaleContext, { localeContext } from "../../context/LocaleContext"
import StoreContext, { defaultStoreContext } from "../../context/StoreContext"
import InterfaceContext, {
  defaultInterfaceContext,
} from "../../context/InterfaceContext"

import Header from "../Header"
import Footer from "../Footer"

import Theme from "../../Theme"
import "@fortawesome/fontawesome-pro/css/all.min.css"
import "./layout.css"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  main {
    flex-grow: 1;
  }
`

class Layout extends React.Component {
  state = {
    locale: {
      ...localeContext,
      setLocale: locale => {
        if (locale !== "en" && locale !== "da-DK") {
          locale = "da-DK"
        }

        this.setState(prevState => ({
          locale: {
            ...prevState.locale,
            locale,
          },
        }))
      },
      t: key => {
        const strings = this.state.locale.strings[this.state.locale.locale]

        const result = strings[key]
        if (!result) {
          return key
        }

        return result
      },
    },
    interface: {
      ...defaultInterfaceContext,
      toggleSideNav: () => {
        this.setState(prevState => ({
          interface: {
            ...prevState.interface,
            isCartOpen: false,
            isSideNavOpen: !prevState.interface.isSideNavOpen,
          },
        }))
      },
      toggleCart: () => {
        this.setState(prevState => ({
          interface: {
            ...prevState.interface,
            isSideNavOpen: false,
            isCartOpen: !prevState.interface.isCartOpen,
          },
        }))
      },
    },
  }

  componentDidMount() {
    this.state.locale.setLocale(this.props.locale)
    window.addEventListener("scroll", this.handleScroll, { passive: true })
  }

  componentDidUpdate(prevProps) {
    if (this.props.locale && prevProps.locale) {
      if (this.props.locale.locale !== prevProps.locale.locale) {
        this.state.locale.setLocale(this.props.locale)
      }
    } else {
      this.state.locale.setLocale(this.props.locale)
    }
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll)
  }

  handleScroll = () => {
    this.setState(prevState => ({
      interface: {
        ...prevState.interface,
        prevScrollPos: prevState.interface.scrollPos,
        scrollPos: Math.max(0, window.pageYOffset),
      },
    }))
  }

  render() {
    const { children, locale } = this.props

    return (
      <StaticQuery
        query={graphql`
          query Menu {
            daMenu: contentfulNavigationMenu(
              name: { eq: "Main Nav" }
              node_locale: { eq: "da-DK" }
            ) {
              items {
                ... on ContentfulPage {
                  id
                  pageTitle
                  slug
                }
              }
            }
            enMenu: contentfulNavigationMenu(
              name: { eq: "Main Nav" }
              node_locale: { eq: "en" }
            ) {
              items {
                ... on ContentfulPage {
                  id
                  pageTitle
                  slug
                }
              }
            }
          }
        `}
        render={({ daMenu, enMenu }) => (
          <ThemeProvider theme={Theme}>
            <LocaleContext.Provider value={this.state.locale}>
              <InterfaceContext.Provider value={this.state.interface}>
                <Container>
                  <Header
                    locale={locale}
                    sideNav={locale === "en" ? enMenu.items : daMenu.items}
                  />
                  <main>{children}</main>
                  <footer>
                    <Footer />
                  </footer>
                </Container>
              </InterfaceContext.Provider>
            </LocaleContext.Provider>
          </ThemeProvider>
        )}
      />
    )
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
