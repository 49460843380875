import styled from '@emotion/styled'

const ButtonGroup = styled.div`
  display: flex;
  align-items: center;

  a {
    font-weight: 600;
    font-size: 0.8rem;
    padding: 0.5rem 1rem;
    border: 1px solid white;
    background: transparent;
    transition: background 500ms;
  }
  a:nth-of-type(1) {
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
    border-right: 0;
  }

  a:nth-of-type(2) {
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
  }

  a:hover {
    background: white;
    color: black;
  }
`

export default ButtonGroup
