import React from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import Img from "gatsby-image"
import { useInView } from "react-intersection-observer"

import Link from "../../../utils/Link"
import mq from "../../../utils/mq"
import CTA from "./CTA"

const imgLeftTextRight = "Image Left, Text Right"
const imgRightTextLeft = "Image Right, Text Left"
const imgTopTextBottom = "Image Top, Text Bottom"
const imgBottomTextTop = "Image Bottom, Text Top"

const Tile = styled.div`
  position: relative;

  display: flex;
  width: 100%;

  .content {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 2rem 0;
    max-width: 500px;
    margin: auto;
  }

  background-color: ${props => props.backgroundColor};
  ${props =>
    mq({
      textAlign: ["center", "center", "left"],
      width: ["100%", "100%", "60%", "60%"],
    })}
`

const MediaImg = styled(Img)`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
`

const Wrapper = styled.div`
  display: flex;
  max-width: 1280px;

  opacity: 0.2;
  transform: translate3d(0, 20px, 0);

  ${props =>
    props.animate &&
    `
    animation-name: fadeInUp;
    animation-duration: 2s;
    animation-fill-mode: both;
  `}

  flex-wrap: wrap;
  height: 100%;
  width: 100%;
  justify-content: space-between;
  margin: auto;
  ${props =>
    mq({
      padding: [
        `2rem ${props.theme.spacing.padding.content}`,
        `2rem calc(${props.theme.spacing.padding.content} * 3)`,
        `2rem calc(${props.theme.spacing.padding.content} * 3)`,
      ],
    })}

  ${props => {
    switch (props.cardStyle) {
      case imgLeftTextRight:
        return `
          flex-direction: row-reverse;
        `
      case imgRightTextLeft:
        return `
          flex-direction: row;
        `
      case imgTopTextBottom:
        return `
          flex-direction: column-reverse;
          align-items: center;
          ${Tile} {
            height: unset;
          }
          padding-top: ${props.theme.spacing.padding.content};
          padding-bottom: ${props.theme.spacing.padding.content};
        `
      case imgBottomTextTop:
        return `
          flex-direction: column;
          align-items: center;
          ${Tile} {
            height: unset;
          }
          padding-top: ${props.theme.spacing.padding.content};
          padding-bottom: ${props.theme.spacing.padding.content};
        `
      default:
        return ""
    }
  }}
`

const Title = styled.h2`
  font-size: ${props => props.theme.typography.header};
  margin-bottom: 1rem;
`

const Body = styled.div``

const MediaContainer = styled.div`
  ${mq({
    height: ["", "", "100%"],
    width: ["100%", "100%", "35%", "35%"],
    order: ["-1", "-1", "initial"],
  })}

  img {
    ${mq({
      objectFit: [
        "contain !important",
        "contain !important",
        "cover !important",
        "cover !important",
      ],
    })}
  }
`

const MediaCard = ({ input }) => {
  const [ref, inView, entry] = useInView({
    threshold: 0.2,
    triggerOnce: true,
  })
  const { style, backgroundColor, media, title, cta } = input
  let { body } = input

  if (!body) {
    body = {
      childMarkdownRemark: {
        html: "",
      },
    }
  }

  const isPdf = media && media.file.contentType === "application/pdf"

  const handleClick = () => {
    if (isPdf) {
      window.open(media.file.url, "_blank")
    }
  }

  return (
    <Wrapper ref={ref} animate={inView} cardStyle={style}>
      <Tile backgroundColor={backgroundColor}>
        <div className="content">
          <Title>{title}</Title>
          <Body
            dangerouslySetInnerHTML={{ __html: body.childMarkdownRemark.html }}
          />
          {!!cta && <CTA onClick={handleClick}>{cta}</CTA>}
        </div>
      </Tile>
      {!isPdf && (
        <MediaContainer>
          <MediaImg fluid={media.fluid} />
        </MediaContainer>
      )}
    </Wrapper>
  )
}

MediaCard.propTypes = {
  input: PropTypes.object.isRequired,
}

export default MediaCard
