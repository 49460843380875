import React from 'react'

export const defaultStoreContext = {
  loading: true,
  adding: false,
  cart: { items: [], currencyCode: 'DKK' },
  addCondiment: () => {},
  addLineItem: () => {},
  removeLineItem: () => {},
  updateLineItem: () => {},
}

const StoreContext = React.createContext(defaultStoreContext)

export default StoreContext

