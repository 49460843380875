import React from "react"
import styled from "@emotion/styled"
import { StaticQuery, graphql } from "gatsby"

import mq from "../../utils/mq"
import Link from "../../utils/Link"
import LocaleContext from "../../context/LocaleContext"

import Logo from "../../assets/logo-guru.png"
import NewsletterInput from "../ui/NewsletterInput"

const Wrapper = styled.div`
  position: relative;
  font-size: calc(0.8rem + 0.2vw);

  display: flex;
  flex-direction: column;

  width: 100%;
  min-height: 400px;

  text-transform: uppercase;
  color: white;
  background-color: ${props => props.theme.colors.brand};

  padding-left: ${props => props.theme.spacing.padding.content};
  padding-right: ${props => props.theme.spacing.padding.content};
  padding-top: ${props => props.theme.spacing.padding.content};

  ${props => props.theme.breakpoints.tablet} {
    padding-left: calc(1.5 * ${props => props.theme.spacing.padding.content});
    padding-right: calc(1.5 * ${props => props.theme.spacing.padding.content});
    padding-top: calc(1.5 * ${props => props.theme.spacing.padding.content});
  }

  ${props => props.theme.breakpoints.desktop} {
    padding-left: calc(2 * ${props => props.theme.spacing.padding.content});
    padding-right: calc(2 * ${props => props.theme.spacing.padding.content});
    padding-top: calc(2 * ${props => props.theme.spacing.padding.content});
  }
`

const Header = styled.div`
  width: 100%;
  margin-bottom: 1rem;
  img {
    max-height: 40px;
  }
`

const MenuContainer = styled.div`
  display: flex;

  flex-direction: column;

  ${props => props.theme.breakpoints.tablet} {
    flex-direction: row;
  }

  flex-wrap: wrap;
  flex-grow: 1;

  width: 100%;
`

const Menu = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 150px;
  min-width: 350px;
  flex-wrap: wrap;
  flex-grow: 1;
  margin-top: 1rem;
  margin-bottom: 1rem;
`

const Address = styled.div`
  margin-left: -1rem;
  margin-right: -1rem;
  color: gray;
  background-color: #1d1d1d;

  height: 120px;
  justify-content: center;

  display: flex;
  align-items: center;
  font-size: ${props => props.theme.typography.minor};

  ${props => props.theme.breakpoints.tablet} {
    height: 60px;
  }

  .content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-left: ${props => props.theme.spacing.padding.content};
    padding-right: ${props => props.theme.spacing.padding.content};
    width: 100%;

    div {
      width: 100%;
      text-align: center;
      padding-top: 1rem;
      padding-bottom: 1rem;
    }

    ${props => props.theme.breakpoints.tablet} {
      div {
        width: unset;
        text-align: left;
      }

      margin-left: -2rem;
      margin-right: -2rem;
      padding-left: calc(1.5 * ${props => props.theme.spacing.padding.content});
      padding-right: calc(
        1.5 * ${props => props.theme.spacing.padding.content}
      );
    }

    ${props => props.theme.breakpoints.desktop} {
      padding-left: calc(2 * ${props => props.theme.spacing.padding.content});
      padding-right: calc(2 * ${props => props.theme.spacing.padding.content});
    }
  }

  a {
    text-decoration: underline;
    color: gray;
  }
`

const Item = styled(Link)`
  display: block;
  margin-bottom: 0.5rem;
`

const Footer = () => {
  return (
    <StaticQuery
      query={graphql`
        {
          daMenu: contentfulNavigationMenu(name: { eq: "Footer Navigation" }) {
            items {
              ... on ContentfulPage {
                id
                pageTitle
                slug
              }
            }
          }
          enMenu: contentfulNavigationMenu(
            name: { eq: "Footer Navigation" }
            node_locale: { eq: "en" }
          ) {
            items {
              ... on ContentfulPage {
                id
                pageTitle
                slug
              }
            }
          }
        }
      `}
      render={({ daMenu, enMenu }) => (
        <LocaleContext.Consumer>
          {({ t, locale }) => (
            <Wrapper>
              <Header>
                <img alt="Guru Restaurant Logo" src={Logo} />
              </Header>
              <MenuContainer>
                <Menu>
                  <Item to="/takeaway">{t("Takeaway")}</Item>
                  {(locale === "en" ? enMenu : daMenu).items.map(nav => (
                    <Item key={nav.id} to={`/${nav.slug}`}>
                      {nav.pageTitle}
                    </Item>
                  ))}
                </Menu>
                <NewsletterInput />
              </MenuContainer>
              <Address>
                <div className="content">
                  <div>©1973-2020 All Rights Reserved. Guru</div>
                  <div>
                    <a href="tel:+4552709204">+45 52 70 92 04</a>
                  </div>
                </div>
              </Address>
            </Wrapper>
          )}
        </LocaleContext.Consumer>
      )}
    />
  )
}

export default Footer
