import React from "react"

export const localeContext = {
  strings: {
    "da-DK": {
      "Make a": "Lav en",
      Reservation: "Reservation",
      s: "er",
      Order: "Bestil",
      Takeaway: "Takeaway",
      "MAKE A RESERVATION": "BOOK BORD",
      "ORDER TAKEAWAY": "BESTIL TAKEAWAY",
      Takeaway: "Takeaway",
      "Make a Reservation": "Book Bord",
      newsletter_prompt: "Skriv dig op til vores nyhedsbrev",
      newsletter_thanks: "Tak! Du er blevet skrevet op til vores nyhedsbrev",
      newsletter_error: "Noget gik galt — prøv igen",
      Submit: "OK",
    },
    en: {
      newsletter_prompt: "Sign up for our newsletter",
      newsletter_thanks:
        "Thank you! You have been subscribed to our newsletter",
      newsletter_error: "Something went wrong please try again.",
    },
  },
  locale: "da-DK",
  setLocale: locale => {},
  t: key => {},
}

export default React.createContext(localeContext)
