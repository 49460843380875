import axios from 'axios'

const API_URL = process.env.GATSBY_API_URL || 'http://localhost:7000/api/v2/store'

const client = axios.create({
  baseURL: API_URL,
  withCredentials: true
})

export default function getData(path) {
  return client.get(path)
}

export function request(method, path, payload={}) {
  const options = {
    method,
    url: path,
    data: {
      data: payload
    }
  }

  return client(options)
}

