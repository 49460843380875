import React from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import Img from "gatsby-image"
import { useInView } from "react-intersection-observer"

import InterfaceContext from "../../../context/InterfaceContext"

import mq from "../../../utils/mq"
import Link from "./Link"
import CTA from "./CTA"

const TileContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  opacity: 0.2;
  transform: translate3d(0, 20px, 0);
  height: 100%;

  ${props =>
    props.animate &&
    `
    animation-name: fadeInUp;
    animation-duration: 2s;
    animation-fill-mode: both;
  `}

  ${props =>
    mq({
      color: ["white", "white", props.textColor || props.theme.colors.text],
    })}

  ${CTA} {
    ${props =>
      mq({
        color: ["white", "white", props.textColor || props.theme.colors.text],
      })}
  }

  ${props =>
    mq({
      padding: [
        `${props.theme.spacing.padding.content}`,
        `calc(2 * ${props.theme.spacing.padding.content})`,
        `calc(3 * ${props.theme.spacing.padding.content})`,
      ],
    })}
`

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #00000055;
  z-index: 2;
`

const TileImg = styled(Img)`
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-position: center;
  object-fit: cover;

  width: 100%;
  height: 100%;
  z-index: 1;
`

const Tile = styled(Link)`
  padding: calc(1.5rem + 5vw);
  position: relative;
  display: flex;
  flex-direction: column;

  width: 100%;

  background-color: ${props => props.backgroundColor};

  ${Title}, ${Body}, ${CTA} {
    z-index: 2;
  }

  ${props => !props.sectionHasImage && `height: 100% !important`};

  ${props => props.hideOnMobile && `display: none`};
  ${props =>
    props.backgroundImage &&
    `
      background-color: transparent;
      height: ${props.theme.sections.medium};
      margin-left: 1.5rem;
      margin-right: 1.5rem;
    `}
  ${props => props.isFeature && props.backgroundImage && `display: none;`}
  ${props =>
    props.isFeature &&
    !props.backgroundImage &&
    `
      padding-left: 1.5rem;
      padding-right: 1.5rem;
    `}

  ${Body} {
    flex-grow: 1;
  }

  ${props => props.theme.breakpoints.tablet} {
    ${props => props.hideOnMobile && `display: flex`};
    justify-content: center;
    flex: 1;
    margin: 0;
    height: 100%;

    ${Body} {
      flex-grow: unset;
    }
  }

  
  ${props => props.theme.breakpoints.desktop} {
    display: flex;
    width: unset;
    margin: 0;
    height: 100%;

    flex: 12;

    background-color: ${props => props.backgroundColor};

    ${props =>
      props.isFeature &&
      props.backgroundImage &&
      `
        flex: 8;
        padding: 3.5rem;
        &:first-of-type {
          padding-right: 0;
        }
      `}

    ${props =>
      props.isFeature &&
      !props.backgroundImage &&
      `
        * + & {
          padding-left: 1rem;
        }
      `}
  }
`

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  .no-img + .img-tile {
    order: -1;
  }

  ${props =>
    props.hasImage
      ? `
      min-height: ${props.theme.sections.medium};
    `
      : `
      ${Tile} {
        padding-bottom: 0;
      }
      ${Tile}:nth-of-type(2) {
        padding-top: 0;
      }
    `}

  ${props => props.theme.breakpoints.tablet} {
    .no-img + .img-tile {
      order: unset;
    }
    ${props =>
      !props.hasImage &&
      `
      height: 100% !important;
      ${Tile} {
        padding-bottom: 1rem;
        padding-top: 1rem;
      }
    `}
    height: ${props => props.theme.sections.large};
  }

  ${props => props.theme.breakpoints.desktop} {
    height: ${props => props.theme.sections.full};
    ${props =>
      props.isFeature &&
      `
        height: ${props.theme.sections.medium};
        justify-content: center;
      `}
  }

  ${props => props.theme.breakpoints.desktop} {
    height: ${props => props.theme.sections.fullHd};
    ${props =>
      props.isFeature &&
      `
        height: ${props.theme.sections.large};
      `}
  }
`

const TileLink = styled(Link)`
  text-decoration: none;

  &:hover {
    ${CTA}::after {
      margin-left: 1.5rem;
    }
  }
`

const Title = styled.h2`
  font-size: ${props => props.theme.typography.header};
`

const Body = styled.div`
  margin-bottom: 1rem;
`

const TileModule = ({ input, link, sectionHasImage }) => {
  const [ref, inView, entry] = useInView({
    threshold: 0.2,
    triggerOnce: true,
  })
  const { backgroundColor, textColor, backgroundImage, title, cta } = input
  let { body } = input

  if (!body) {
    body = {
      childMarkdownRemark: {
        html: "",
      },
    }
  }

  return (
    <Tile
      className={backgroundImage ? "img-tile" : "no-img"}
      sectionHasImage={sectionHasImage}
      backgroundColor={backgroundColor}
      backgroundImage={backgroundImage}
      link={link}
    >
      {backgroundImage && <TileImg fluid={backgroundImage.fluid} />}
      {backgroundImage && <Overlay />}
      <TileContent ref={ref} animate={inView} textColor={textColor}>
        <Title>{title}</Title>
        <Body
          dangerouslySetInnerHTML={{
            __html: body.childMarkdownRemark.html,
          }}
        />
        {!!cta && <CTA textColor={textColor}>{cta}</CTA>}
      </TileContent>
    </Tile>
  )
}

const LinkWrap = ({ input }) => {
  const { tiles } = input
  const imgTile = tiles.find(t => !!t.backgroundImage)

  return (
    <Wrapper hasImage={!!imgTile}>
      {tiles.map(t => (
        <TileModule
          key={t.id}
          link={t.link}
          sectionHasImage={!!imgTile}
          input={t}
        />
      ))}
    </Wrapper>
  )
}

TileModule.propTypes = {
  input: PropTypes.object.isRequired,
}

export default LinkWrap
