import React from "react"
import styled from "@emotion/styled"

import CardBlock from "./CardBlock"
import Banner from "./Banner"
import ImageGallery from "./ImageGallery"
import Tile from "./Tile"
import Hero from "./Hero"
import RichText from "./RichText"
import MediaCard from "./MediaCard"
import Image from "./Image"

const Content = styled.div`
  display: flex;
  flex-direction: column;
  background: ${props => props.theme.colors.background};
  color: ${props => props.theme.colors.text};
`

export default class ContentModules extends React.Component {
  render() {
    const { allModules, isHome } = this.props
    const module = allModules.map((s, index) => {
      switch (s.internal.type) {
        // These are the API IDs of the slices
        case "ContentfulImageLink":
          return <Image key={s.id} input={s} first={index === 0} />
        case "ContentfulCardBlock":
          return <CardBlock key={s.id} input={s} first={index === 0} />
        case "ContentfulBanner":
          return <Banner key={s.id} input={s} first={index === 0} />
        case "ContentfulGallery":
          return <ImageGallery key={s.id} input={s} first={index === 0} />
        case "ContentfulRichText":
          return <RichText key={s.id} input={s} first={index === 0} />
        case "ContentfulTileSection":
          return <Tile key={s.id} input={s} first={index === 0} />
        case "ContentfulHero":
          return (
            <Hero key={s.id} input={s} isHome={isHome} first={index === 0} />
          )
        case "ContentfulMediaCard":
          return <MediaCard key={s.id} input={s} first={index === 0} />
        default:
          return null
      }
    })
    return <Content>{module}</Content>
  }
}
