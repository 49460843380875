const Theme = {
  breakpoints: {
    mobile: "@media only screen and (min-width: 576px)",
    tablet: "@media only screen and (min-width: 768px)",
    desktop: "@media only screen and (min-width: 992px)",
    hd: "@media only screen and (min-width: 1200px)",
  },
  sections: {
    full: "720px",
    large: "680px",
    medium: "620px",
    small: "580px",
    smallest: "520px",
  },
  spacing: {
    padding: {
      content: "1rem",
    },
  },
  colors: {
    background: "#1b1b1c",
    secondary: "#7c766c",
    brand: "#212121",
    danger: "#ff5e57",
    light: "#f8f8f8",
    text: "white",
    textHover: "#485460",
    textMuted: "#6c757d",
  },
  navbar: {
    height: "60px",
    logoHeight: "1.2rem",
  },
  typography: {
    minor: "0.8rem",
    body: "1rem",
    header: "1.25rem",
    major: "1.563rem",
  },
}

export default Theme
