import React from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import Img from "gatsby-image"
import { useInView } from "react-intersection-observer"

import mq from "../../../utils/mq"

const Title = styled.h2`
  font-size: ${props => props.theme.typography.major};
  text-transform: uppercase;
`

const Body = styled.div`
  white-space: pre-wrap;
  font-size: ${props => props.theme.typography.header};
  flex-grow: 1;
`

const FakeBackgroundImg = styled(Img)`
  position: absolute !important;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  object-position: center;
  object-fit: cover;

  width: 100%;
  height: 100%;

  z-index: 1;
`

const Banner = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 100%;
  height: 45vh;
  text-decoration: none;

  color: ${props => props.textColor || "white"};
  ${props =>
    mq({
      padding: [
        `0 ${props.theme.spacing.padding.content}`,
        `0 calc(${props.theme.spacing.padding.content} * 3)`,
        `0 calc(${props.theme.spacing.padding.content} * 3)`,
      ],
    })}

  .content {
    width: 100%;
    max-width: 1280px;
    margin: auto;
    opacity: 0.2;
    transition: opacity 2s;

    ${props =>
      props.animate &&
      `
      opacity: 1;
    `}

    z-index: 10;
  }
`

const BannerVideo = styled.video`
  position: absolute !important;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  object-position: center;
  object-fit: cover;

  width: 100%;
  height: 100%;

  z-index: 1;
`

const Overlay = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: ${props => props.tint || "#000000"}55;
  z-index: 2;
`

const BannerModule = ({ input, first }) => {
  const [ref, inView, entry] = useInView({
    threshold: 0.2,
    triggerOnce: true,
  })

  const { media, title, textColor, tint } = input
  let { body } = input

  if (!body) {
    body = {
      childMarkdownRemark: {
        html: "",
      },
    }
  }

  return (
    <Banner ref={ref} animate={inView} textColor={textColor}>
      <Overlay tint={tint} />
      {media &&
        media.file &&
        (media.file.contentType === "video/mp4" ? (
          <BannerVideo autoPlay loop muted playsInline>
            <source src={media.file.url} type="video/mp4" />
          </BannerVideo>
        ) : (
          <FakeBackgroundImg fluid={media.fluid} />
        ))}
      <div className="content">
        <Title as={first ? "h1" : "h2"} textColor={textColor}>
          {title}
        </Title>
        <Body
          textColor={textColor}
          dangerouslySetInnerHTML={{ __html: body.childMarkdownRemark.html }}
        />
      </div>
    </Banner>
  )
}

export default BannerModule
