import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import Img from 'gatsby-image'
import { BLOCKS, MARKS } from '@contentful/rich-text-types'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'

import Link from '../../../utils/Link'
import mq from '../../../utils/mq'

const Container = styled.div`
  padding: 2rem calc(${props => props.theme.spacing.padding.content} * 3);
  ${props =>
    mq({
      padding: [
        `2rem ${props.theme.spacing.padding.content}`,
        `2rem ${props.theme.spacing.padding.content}`,
        `2rem calc(${props.theme.spacing.padding.content} * 3)`,
        `2rem calc(${props.theme.spacing.padding.content} * 3)`,
      ],
    })}

  background-color: ${props => props.backgroundColor};
  color: ${props => props.textColor};

  p {
    margin-bottom: 1rem;
    white-space: pre-wrap;
  }

  a {
    text-decoration: underline;
  }
`

const Content = styled.div`
  margin: auto;
  width: 100%;
  max-width: 680px;
`

const Bold = ({ children }) => <span className="bold">{children}</span>
const Text = ({ children }) => <p className="align-center">{children}</p>

const options = {
  renderMark: {
    [MARKS.BOLD]: text => <Bold>{text}</Bold>,
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
  },
}

const RichText = ({ input }) => {
  const { body, textColor, backgroundColor } = input

  return (
    <Container textColor={textColor} backgroundColor={backgroundColor}>
      <Content>{documentToReactComponents(body.json, options)}</Content>
    </Container>
  )
}

RichText.propTypes = {
  input: PropTypes.object.isRequired,
}

export default RichText
