import styled from "@emotion/styled"

import mq from "../../utils/mq"

const NavItem = styled.div`
  cursor: pointer;
  font-size: 2rem;
  align-self: center;
  display: flex;

  justify-self: ${props => props.justifyMobile || props.justifySelf};

  ${props => props.theme.breakpoints.desktop} {
    justify-self: ${props => props.justifySelf};
  }

  .hover {
    &:hover {
      color: ${props => props.theme.colors.textHover};
    }
  }

  .align-center {
    display: flex;
    align-items: center;
  }
`

export default NavItem
