import styled from "@emotion/styled"

const CTA = styled.div`
  cursor: pointer;
  display: inline-block;
  width: 100%;
  font-size: ${props => props.theme.typography.body};
  text-decoration: none;
  color: ${props => props.textColor || props.theme.colors.text};
  font-family: "Poppins", sans-serif;
  &::after {
    display: inline-block;
    padding-bottom: 3px;
    margin-left: 1rem;
    font-family: "Font Awesome 5 Pro";
    font-weight: 300;
    content: "\f178";
    vertical-align: middle;
    transition: margin-left 200ms;
  }

  &:hover {
    &::after {
      margin-left: 1.5rem;
    }
  }

  ${props => props.theme.breakpoints.desktop} {
    font-size: ${props => props.theme.typography.header};
  }
`

export default CTA
