import React from "react"
import { navigate } from "gatsby"
import PageLink from "../../../utils/Link"

const Link = ({ children, link, className }) => {
  if (!link) {
    return <span className={className}>{children}</span>
  }

  const { url, media, page } = link

  if (url) {
    return (
      <PageLink className={className} to={url}>
        {children}
      </PageLink>
    )
  }

  if (media && media.file.url) {
    return (
      <span
        className={className}
        onClick={() => window.open(media.file.url, "_blank")}
      >
        {children}
      </span>
    )
  }

  if (page) {
    return (
      <PageLink className={className} to={`/${page.slug}`}>
        {children}
      </PageLink>
    )
  }

  return children
}

export default Link
