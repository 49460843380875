import PropTypes from "prop-types"
import React from "react"
import styled from "@emotion/styled"

import mq from "../../utils/mq"

import InterfaceContext from "../../context/InterfaceContext"
import LocaleContext from "../../context/LocaleContext"

import Logo from "../../assets/logo-guru.png"
import NavItem from "../ui/NavItem"
import ButtonGroup from "../ui/ButtonGroup"
import Link from "../../utils/Link"

import SideNavigation from "./SideNavigation"

const LangPicker = styled.div`
  font-size: 14px;
`

const NavBar = styled.div`
  position: fixed;
  right: 0;
  left: 0;

  transition: transform 400ms;
  ${props =>
    props.up && `transform: translateY(-${props.theme.navbar.height})`};

  background: linear-gradient(
    180deg,
    ${props => props.theme.colors.brand}64 0%,
    ${props => props.theme.colors.brand}40 50%,
    ${props => props.theme.colors.brand}00 100%
  );

  display: grid;
  grid-template-columns: repeat(3, 1fr);

  height: ${props => props.theme.navbar.height};
  z-index: 1000;
  color: white;

  padding-left: ${props => props.theme.spacing.padding.content};
  padding-right: ${props => props.theme.spacing.padding.content};

  ${props => props.theme.breakpoints.tablet} {
    padding-left: calc(1.5 * ${props => props.theme.spacing.padding.content});
    padding-right: calc(1.5 * ${props => props.theme.spacing.padding.content});
  }

  ${props => props.theme.breakpoints.desktop} {
    padding-left: calc(2 * ${props => props.theme.spacing.padding.content});
    padding-right: calc(2 * ${props => props.theme.spacing.padding.content});
  }
`

const HeaderLogo = styled.img`
  max-height: 2.5rem;
`

class Header extends React.Component {
  render() {
    const { siteTitle, productNavigation, sideNav, locale } = this.props

    let home = "/"
    if (locale === "en") {
      home = "/en"
    }

    return (
      <header>
        <LocaleContext.Consumer>
          {({ locale }) => (
            <InterfaceContext.Consumer>
              {({ toggleSideNav, scrollPos, prevScrollPos, isSideNavOpen }) => (
                <div>
                  <NavBar up={scrollPos > prevScrollPos}>
                    <NavItem hover justifySelf="start">
                      <div className="hover">
                        <i onClick={toggleSideNav} className="fal fa-bars" />
                      </div>
                    </NavItem>
                    <NavItem justifySelf="center">
                      <Link className="align-center logo" to={home}>
                        <HeaderLogo src={Logo} />
                      </Link>
                    </NavItem>
                    <NavItem hideOnMobile justifySelf="end">
                      <LangPicker>
                        <Link to={locale === "en" ? "/" : "/en"}>
                          {locale === "en" ? "Danish" : "English"}
                        </Link>
                      </LangPicker>
                    </NavItem>
                  </NavBar>
                  <SideNavigation
                    menuItems={sideNav}
                    toggleSideNav={toggleSideNav}
                    visible={isSideNavOpen}
                  />
                </div>
              )}
            </InterfaceContext.Consumer>
          )}
        </LocaleContext.Consumer>
      </header>
    )
  }
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
