import React from "react"
import styled from "@emotion/styled"

import mq from "../../utils/mq"
import Link from "../../utils/Link"

import LocaleContext from "../../context/LocaleContext"

import Logo from "../../assets/logo-guru.png"

const Wrapper = styled.div`
  position: absolute;
  z-index: 1000;

  width: 100%;
  height: 100%;

  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  background-color: rgba(0, 0, 0, ${props => (props.visible ? "0.3" : "0")});
  transform: translateX(${props => (props.visible ? "0" : "-100%")});

  ${props =>
    props.visible
      ? `
    transition: background-color 800ms, transform 1ms;
  `
      : `
    transition: background-color 300ms, transform 1ms ease-in-out 300ms;
  `}
`

const SideNavigationContainer = styled.div`
  position: relative;

  display: flex;
  flex-direction: column;

  ${mq({
    width: ["100%", "100%", "500px", "500px"],
  })}

  position: fixed;
  height: 100vh;
  bottom: 0;
  left: 0;
  transform: translateX(${props => (props.visible ? "0" : "-100%")});

  color: white;
  background-color: ${props => props.theme.colors.brand};
  z-index: 1001;

  transition: transform 800ms;
  padding-top: calc(${props => props.theme.spacing.padding.content} / 2);
  padding-bottom: ${props => props.theme.spacing.padding.content};
  padding-left: ${props => props.theme.spacing.padding.content};
  padding-right: ${props => props.theme.spacing.padding.content};
`

const SideHeader = styled.div`
  display: flex;
  font-size: ${props => props.theme.typography.major};
  i {
    cursor: pointer;
  }
`

const SideBody = styled.div`
  margin-top: 100px;
  flex-grow: 1;
`

const NavItem = styled(Link)`
  display: block;
  font-family: "Poppins", sans-serif;
  text-decoration: none;
  color: white;
  font-weight: 500;
  font-size: ${props => (props.isFeatured ? `1.8rem` : "1.2rem")};
  margin-bottom: 0.4rem;
`

const SocialIcons = styled.div`
  i {
    margin-right: 1rem;
    font-size: 1.5rem;
  }
`

const SideNavigation = ({ menuItems, visible, toggleSideNav }) => {
  return (
    <LocaleContext.Consumer>
      {({ t }) => (
        <>
          <SideNavigationContainer visible={visible}>
            <SideHeader>
              <i onClick={toggleSideNav} className="fal fa-times" />
            </SideHeader>
            <SideBody>
              <NavItem to="/takeaway" isFeatured={true}>
                {t("ORDER TAKEAWAY")}
              </NavItem>
              <br />
              <br />
              {menuItems.map(item => (
                <NavItem to={`/${item.slug}`} key={item.id}>
                  {item.pageTitle}
                </NavItem>
              ))}
            </SideBody>
            <SocialIcons>
              <Link to={"https://www.instagram.com/guru.dk"}>
                <i className="fab fa-instagram" />
              </Link>
              <Link to={"https://www.facebook.com/guru.denmark"}>
                <i className="fab fa-facebook-square" />
              </Link>
            </SocialIcons>
          </SideNavigationContainer>
          <Wrapper visible={visible} onClick={toggleSideNav} />
        </>
      )}
    </LocaleContext.Consumer>
  )
}

export default SideNavigation
