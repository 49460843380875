import React from 'react'
import styled from "@emotion/styled"
import PropTypes from 'prop-types'
import Img from 'gatsby-image'

import Link from '../../../utils/Link'
import CTA from './CTA'

const Gallery = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-gap: 1rem;

  margin-top: 2rem;
  margin-bottom: 2.5rem;

  width: 100%;
`

const Container = styled.div`
  width: 100%;
  padding: ${props => props.theme.spacing.padding.content};
  color: ${props => props.theme.colors.text};
`

const Title = styled.h2`
  display: inline-block;
  text-decoration: none !important;
  font-size: ${props => props.theme.typography.major};
  color: ${props => props.theme.colors.text};
`

const Image = styled(Img)` `

const ImageGallery = ({input}) => {
  const { title, cta, images, linkUrl } = input

  return (
    <Container>
      <Link to={linkUrl}>
        <Title>{title}</Title>
      </Link>
      <Gallery>
        {images.map(i => {
          return (
            <Image key={i.id} fluid={i.fluid} />
          )
        })}
      </Gallery>
      <Link to={linkUrl}>
        <CTA>{cta}</CTA>
      </Link>
    </Container>
  )
}

ImageGallery.propTypes = {
  input: PropTypes.object
}

export default ImageGallery
