import React, { useState, useEffect, useRef, useContext } from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import Img from "gatsby-image"

import InterfaceContext from "../../../context/InterfaceContext"
import Link from "../../../utils/Link"
import mq from "../../../utils/mq"
import CTA from "./CTA"
import ActionGroup from "../../ui/ActionGroup"

const HeroLink = styled(Link)``

const Title = styled.h2`
  font-size: ${props => props.theme.typography.major};
  text-transform: uppercase;
`

const Body = styled.div`
  white-space: pre-wrap;
  font-size: ${props => props.theme.typography.header};
  flex-grow: 1;
`

const FakeBackgroundImg = styled(Img)`
  position: absolute !important;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  object-position: center;
  object-fit: cover;

  width: 100%;
  height: 100%;

  z-index: 1;
`

const Content = styled.div`
  transform: translateY(${props => props.offset}px);
`

const Hero = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 100%;
  height: calc(60vh - ${props => props.theme.navbar.height});
  overflow: hidden;
  text-decoration: none;

  color: ${props => props.textColor || props.theme.colors.text};

  .content {
    opacity: 0.2;
    animation-name: fadeInUp;
    animation-duration: 2s;
    animation-fill-mode: both;

    z-index: 10;

    ${props => props.theme.breakpoints.tablet} {
      margin-top: -100px;
    }

    ${props =>
      mq({
        padding: [
          `0 ${props.theme.spacing.padding.content}`,
          `0 calc(${props.theme.spacing.padding.content} * 3)`,
          `0 calc(${props.theme.spacing.padding.content} * 3)`,
        ],
      })}
  }

  ${props => props.theme.breakpoints.tablet} {
    height: calc(70vh - ${props => props.theme.navbar.height});
  }

  ${props => props.theme.breakpoints.desktop} {
    height: calc(85vh - ${props => props.theme.navbar.height});
  }

  ${props => props.theme.breakpoints.hd} {
    height: calc(95vh - ${props => props.theme.navbar.height});
  }
`

const HeroVideo = styled.video`
  transition: filter 400ms;
  filter: blur(${props => (props.blur > 10 ? "20" : "0")}px);
  position: absolute !important;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  object-position: center;
  object-fit: cover;

  width: 100%;
  height: 100%;

  z-index: 1;
`

const Overlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #00000055;
  z-index: 2;
`

const HeroModule = ({ input, isHome, first }) => {
  const containerRef = useRef()
  const [height, setHeight] = useState(0)
  const [heroTop, setHeroTop] = useState(0)
  const { scrollPos } = useContext(InterfaceContext)

  useEffect(() => {
    setHeight(containerRef.current.offsetHeight)
    setHeroTop(containerRef.current.offsetTop)
  }, [containerRef])

  const { media, title, cta, linkUrl, textColor } = input
  let { body } = input

  if (!body) {
    body = {
      childMarkdownRemark: {
        html: "",
      },
    }
  }

  const offset = (Math.max(scrollPos - heroTop, 0) * 30) / height

  return (
    <Hero ref={containerRef} textColor={textColor}>
      <Overlay />
      {media.file.contentType === "video/mp4" ? (
        <HeroVideo blur={offset} autoPlay loop muted playsInline>
          <source src={media.file.url} type="video/mp4" />
        </HeroVideo>
      ) : (
        <FakeBackgroundImg fluid={media.fluid} />
      )}
      <Content className="content" offset={offset * 10}>
        <Title as={first ? "h1" : "h2"} textColor={textColor}>
          {title}
        </Title>
        <Body
          textColor={textColor}
          dangerouslySetInnerHTML={{ __html: body.childMarkdownRemark.html }}
        />
        {!!cta && (
          <Link to={linkUrl}>
            <CTA textColor={textColor}>{cta}</CTA>
          </Link>
        )}
        {isHome && <ActionGroup />}
      </Content>
    </Hero>
  )
}

HeroModule.propTypes = {
  input: PropTypes.object.isRequired,
}

export default HeroModule
