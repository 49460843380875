import React, { useState, useEffect, useContext } from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import Img from "gatsby-image"
import { useInView } from "react-intersection-observer"

import InterfaceContext from "../../../context/InterfaceContext"
import Link from "./Link"
import mq from "../../../utils/mq"
import CTA from "./CTA"

const Title = styled.h2`
  font-size: ${props => props.theme.typography.header};
  text-transform: uppercase;

  ${props => props.theme.breakpoints.desktop} {
    font-size: ${props => props.theme.typography.major};
  }
`

const Body = styled.div`
  white-space: pre-wrap;
  font-size: ${props => props.theme.typography.minor};
  flex-grow: 1;

  ${props => props.theme.breakpoints.desktop} {
    font-size: ${props => props.theme.typography.body};
  }
`

const ImageContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  &::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
  }
`

const Image = styled(Img)`
  height: 100%;
  width: 100%;
`

const Box = styled.div`
  transform: translateY(${props => props.offset / 2}px);
  background-color: ${props => props.theme.colors.secondary};
  z-index: 1000;
  position: absolute;
  left: -1rem;
  bottom: -6rem;
  padding: calc(2 * ${props => props.theme.spacing.padding.content});

  box-shadow: #212121 0px 0 3px;

  ${props => props.theme.breakpoints.tablet} {
    padding: calc(3 * ${props => props.theme.spacing.padding.content});
    left: unset;
    bottom: -2rem;
    right: -2rem;
  }
`

const Content = styled(Link)`
  position: relative;

  width: 100%;
  height: 100%;
  margin: auto;
  display: block;

  ${props => props.theme.breakpoints.tablet} {
    max-width: 978px;
    max-height: 636px;
  }
`

const Container = styled.div`
  display: flex;
  margin-top: 10rem;
  margin-bottom: 10rem;

  margin-left: 2rem;
  margin-right: 2rem;

  transform: translate3d(0, 50px, 0);

  ${props =>
    props.animate &&
    `
    animation-name: fadeInUp;
    animation-duration: 2s;
    animation-fill-mode: both;
  `}

  ${props => props.theme.breakpoints.tablet} {
    height: calc(100vh - ${props => props.theme.navbar.height});
  }

  ${props => props.theme.breakpoints.desktop} {
  }

  ${props => props.theme.breakpoints.hd} {
  }
`

const ImageModule = ({ input, isHome }) => {
  const [height, setHeight] = useState(0)
  const [entryTop, setEntryTop] = useState(0)
  const [ref, inView, entry] = useInView({
    threshold: 0.2,
    triggerOnce: true,
  })

  useEffect(() => {
    if (entry && entry.target) {
      setHeight(entry.target.offsetHeight)
      setEntryTop(entry.target.offsetTop)
    }
  }, [entry])

  const { scrollPos } = useContext(InterfaceContext)

  const { image, title, cta, link } = input
  let { body } = input

  if (!body) {
    body = {
      childMarkdownRemark: {
        html: "",
      },
    }
  }

  return (
    <Container ref={ref} animate={inView}>
      <Content link={link}>
        <ImageContainer>
          <Image fluid={image.fluid} />
        </ImageContainer>
        <Box offset={((scrollPos - entryTop) * 50) / height}>
          <Title>{title}</Title>
          <CTA>{cta}</CTA>
        </Box>
      </Content>
    </Container>
  )
}

ImageModule.propTypes = {
  input: PropTypes.object.isRequired,
}

export default ImageModule
